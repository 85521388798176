import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from 'components/Navbar/Navbar';
import Home from 'pages/Home/Home';
import LoginPage from 'pages/Login/LoginPage';
import Footer from 'components/Footer/Footer';

class App extends Component {
    render() {
        return(
            <Router>
                <Navbar />
                <Switch>
                    <Route exact path="/" component={Home} />                
                    <Route exact path="/login-dashboard" component={LoginPage} />                
                </Switch>
                <Footer />
            </Router>
        )
    }
}

export default App;