import React, { Component } from 'react';
import globalData from "data/globalData.json";

class Navbar extends Component {
    render() {        
        return (
            <nav className={`navbar fixed-top header ${globalData.prefixName}-navbar`}>
                <div className="container">
                    <h1 className={`${globalData.prefixName}-logo`}>PORTFOLIO</h1>
                </div>
            </nav>
        )
    }
}

export default Navbar