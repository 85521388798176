import React, { Component } from 'react';
import Modal from "components/Modal/Modal";
import Carousel from "components/Carousel/Carousel";
import firebase from "data/config.js";
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import globalData from "data/globalData.json";
import LazyLoad from 'react-lazyload';
import {sortImageOrder} from "data/functions.js"


class Project extends Component {

    state = {
        isVisible: false,
        projects: "",
        pDetail: ""
    }

    componentDidMount() {
        this.getFirestore();
    }

    onCloseModal = () => { 
        this.setState({isVisible: false, pDetail: ""});
        let element = document.body;
        element.classList.remove("modal-docked");
    };

    onOpenModal = (id) => {
        const fStore = firebase.firestore();
        fStore.collection("projects").doc(id).get().then(doc => {
            if (doc.exists) {
                this.setState({
                    pDetail: doc.data(),
                    isVisible: true
                })
                let element = document.body;
                element.classList.add("modal-docked");
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    };

    renderList = () => { 
        const {projects} = this.state;
        if (projects) {
            let nArrayList = projects.sort(sortImageOrder)      
            return nArrayList.map((info) => {
                return (                    
                    <div key={info.id} className="col-lg-4 col-md-6">
                        <LazyLoad height={100}>
                            <div className="media flex-column">
                                <div className="img-project" 
                                    style={{ backgroundImage: `url(${info.thumbnail ? info.thumbnail : "https://via.placeholder.com/600x250" })` }}
                                ></div>
                                <div className="media-body">
                                    <h5>{info.label}</h5>
                                    <p className="framework">{info.category}</p>
                                    <p className="block-ellipsis">{info.desc}</p>
                                    <button className="btn btn-view" onClick={() => this.onOpenModal(info.id)}>View more</button>
                                </div>
                            </div>
                        </LazyLoad>
                    </div>
                );
            })
        }
    }

    getFirestore = () => {
        const fStore = firebase.firestore();
        fStore.collection("projects").get().then(querySnapshot => {
            let arrayList = [];
            if (querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    let item = doc.data();
                    if (item.display === "0") {
                        arrayList.push(doc.data())
                    }
                });              
                this.setState({
                    projects: arrayList
                })
            }
        });
    }

    render() {
        const {isVisible, pDetail} = this.state;        
        return (
            <section className={`${globalData.prefixName}-project`}>
                <Modal isVisible={isVisible}>
                    {pDetail ? (
                        <div className="container project-modal">
                            <button onClick={this.onCloseModal} className="btn btn-close"><CloseOutlined /></button>
                            <div className="project-content">
                                <h3>{pDetail.label}</h3>
                                <h5>Year: {pDetail.year}</h5>
                                <p><b>Build on: </b>{pDetail.category}</p>
                                <p><b>Description</b></p>
                                <p className="des-text">
                                    {pDetail.desc}
                                </p>
                                <div className="media-demo">
                                    <p><b>Images</b></p>
                                    {pDetail.images ? (
                                        <Carousel images={pDetail.images} />
                                    ) : (
                                        <p>Images will be updated soon</p>
                                    )}
                                </div>                                
                            </div>   
                            <button onClick={this.onCloseModal} className="btn btn-exit">Close</button>                  
                        </div>) : null }                    
                </Modal>
                <div className="container section-box">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                            <div className="section-title text-center">
                                <h2>Projects List</h2>
                                <hr className="lines"/>
                            </div>
                        </div>
                    </div>
                    <div className="row project-boxes">
                       {this.renderList()}
                    </div>
                </div>
            </section>
        )
    }
}

export default Project;
