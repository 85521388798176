import React from 'react';
import Carousel from 'react-images';

const ImageCarousel = ({images}) => {
    return (
        <Carousel 
            views={images}
            styles={{
                container: base => ({
                    ...base,
                    width: "100%",             
                }),
                footer: () => ({
                    display: "none"
                })
            }}
            trackProps={{ swipe : "touch" }}
        />
    )
}

export default ImageCarousel;