import React from "react";

class LoginPage extends React.Component {
    render() { 
        return (
            <section className="container" id="login">
                <div>This is a login page</div>
            </section>
        );
    }
}
 
export default LoginPage;