import React from 'react';
import Hero from 'components/Hero/Hero';
import Project from 'components/Project/Project';
import globalData from "data/globalData.json";

const Home = () => {
    return (
        <div className={`${globalData.prefixName}-main-app`}>
            <Hero />
            <Project />
        </div>
    )
}

export default Home
