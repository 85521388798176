import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';

const fbConfig = {
    apiKey: "AIzaSyDsNxuUQE6iCQ2e5wNWOqcQcQYcujMyndQ",
    authDomain: "sc999-portfolio.firebaseapp.com",
    databaseURL: "https://sc999-portfolio.firebaseio.com",
    projectId: "sc999-portfolio",
    storageBucket: "sc999-portfolio.appspot.com",
}

firebase.initializeApp(fbConfig);

export default firebase;
