import React, { Component } from 'react';
import globalData from 'data/globalData.json';

class Footer extends Component {
    render() {
        return  (
            <footer className={`section-box bg-black ${globalData.prefixName}-footer`}>
                <div className="container">
                    <div className="row center-mobile">
                        <div className="col-sm-12 color-white" dangerouslySetInnerHTML={{__html: globalData.copyrightText}}></div>
                        <div className="col-sm-12 color-white">
                            <i className="fa fa-envelope"></i>
                            <span dangerouslySetInnerHTML={{__html: globalData.contact}}></span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
